@media only screen and (max-width: 767px) {
    .form button[type="submit"] {
        margin: 0.7em 0 0;
    }

    .form input:not([type]),
    .form input[type="text"],
    .form input[type="password"],
    .form input[type="email"],
    .form input[type="url"],
    .form input[type="date"],
    .form input[type="month"],
    .form input[type="time"],
    .form input[type="datetime"],
    .form input[type="datetime-local"],
    .form input[type="week"],
    .form input[type="number"],
    .form input[type="search"],
    .form input[type="tel"],
    .form input[type="color"],
    .form label {
        margin-bottom: 0.3em;
        display: block;
    }

    .group input:not([type]),
    .group input[type="text"],
    .group input[type="password"],
    .group input[type="email"],
    .group input[type="url"],
    .group input[type="date"],
    .group input[type="month"],
    .group input[type="time"],
    .group input[type="datetime"],
    .group input[type="datetime-local"],
    .group input[type="week"],
    .group input[type="number"],
    .group input[type="search"],
    .group input[type="tel"],
    .group input[type="color"] {
        margin-bottom: 0;
    }

    .form-aligned .control-group label {
        margin-bottom: 0.3em;
        text-align: left;
        display: block;
        width: 100%;
    }

    .form-aligned .controls {
        margin: 1.5em 0 0 0;
    }

    /* NOTE:.help-inline is deprecated. Use .form-message-inline instead. */
    .form .help-inline,
    .form-message-inline,
    .form-message {
        display: block;
        font-size: 80%;
        /* Increased bottom padding to make it group with its related input element. */
        padding: 0.2em 0 0.8em;
    }
}
