.form {
    margin-bottom: 20px;
}

.form .has-min-width-225 {
    min-width: 225px;
}

.form input:not([type]),
.form input[type="text"],
.form input[type="password"],
.form input[type="email"],
.form input[type="url"],
.form input[type="date"],
.form input[type="month"],
.form input[type="time"],
.form input[type="datetime"],
.form input[type="datetime-local"],
.form input[type="week"],
.form input[type="number"],
.form input[type="search"],
.form input[type="tel"],
.form input[type="color"],
.form select,
.form textarea {
    display: inline-block;
    padding: 0.35em 0.6em;
    margin: 0 0.5em 0 0;
    border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form select {max-width: 200px}

.form input:not([type]):focus,
.form input[type="text"]:focus,
.form input[type="password"]:focus,
.form input[type="email"]:focus,
.form input[type="url"]:focus,
.form input[type="date"]:focus,
.form input[type="month"]:focus,
.form input[type="time"]:focus,
.form input[type="datetime"]:focus,
.form input[type="datetime-local"]:focus,
.form input[type="week"]:focus,
.form input[type="number"]:focus,
.form input[type="search"]:focus,
.form input[type="tel"]:focus,
.form input[type="color"]:focus,
.form select:focus,
.form textarea:focus {
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    border: 1px solid #FE6005;
    box-shadow: 0 0 3px 0 rgba(235, 120, 1, 0.5);
}

.form input[type="file"]:focus,
.form input[type="radio"]:focus,
.form input[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline: 1px auto #129FEA;
}
.form .checkbox,
.form .radio {
    margin: 0.5em 0;
    display: block;
}
.form input:not([type])[disabled],
.form input[type="text"][disabled],
.form input[type="password"][disabled],
.form input[type="email"][disabled],
.form input[type="url"][disabled],
.form input[type="date"][disabled],
.form input[type="month"][disabled],
.form input[type="time"][disabled],
.form input[type="datetime"][disabled],
.form input[type="datetime-local"][disabled],
.form input[type="week"][disabled],
.form input[type="number"][disabled],
.form input[type="search"][disabled],
.form input[type="tel"][disabled],
.form input[type="color"][disabled],
.form select[disabled],
.form textarea[disabled] {
    cursor: not-allowed;
    background-color: #eaeded;
    color: #cad2d3;
}
.form input[readonly],
.form select[readonly],
.form textarea[readonly] {
    background: #eee; /* menu hover bg color */
    color: #777; /* menu text color */
    border-color: #ccc;
}

.form input:focus:invalid,
.form textarea:focus:invalid,
.form select:focus:invalid {
    color: #b94a48;
    border: 1px solid #ee5f5b;
}
.form input:focus:invalid:focus,
.form textarea:focus:invalid:focus,
.form select:focus:invalid:focus {
    border-color: #e9322d;
}
.form input[type="file"]:focus:invalid:focus,
.form input[type="radio"]:focus:invalid:focus,
.form input[type="checkbox"]:focus:invalid:focus {
    outline-color: #e9322d;
}
.form select {
    border: 1px solid #ccc;
    background-color: white;
}
.form select[multiple] {
    height: auto;
}
.form label {
    margin: 0.5em 0.35em 0.2em;
}
.form fieldset {
    margin: 0;
    padding: 0.35em 0 0.75em;
    border: 0;
}
.form legend {
    display: block;
    width: 100%;
    padding: 0.3em 0;
    margin-bottom: 0.3em;
    font-size: 125%;
    color: #333;
    border-bottom: 1px solid #e5e5e5;
}

.control-group {
    margin-bottom: 0.75em;
}

.form-stacked input:not([type]),
.form-stacked input[type="text"],
.form-stacked input[type="password"],
.form-stacked input[type="email"],
.form-stacked input[type="url"],
.form-stacked input[type="date"],
.form-stacked input[type="month"],
.form-stacked input[type="time"],
.form-stacked input[type="datetime"],
.form-stacked input[type="datetime-local"],
.form-stacked input[type="week"],
.form-stacked input[type="number"],
.form-stacked input[type="search"],
.form-stacked input[type="tel"],
.form-stacked input[type="color"],
.form-stacked select,
.form-stacked label,
.form-stacked textarea {
    display: block;
    margin: 0.25em 0;
}

.form-stacked .errors,
.form-stacked .control-group .errors {
    display: block;
    margin: 0.4em 0 0 0;
}

.form-stacked .errors li,
.form-stacked .control-group .errors li {
    display: block;
    margin: 0;
    color: #FF0000;
}

.form-aligned input,
.form-aligned textarea,
.form-aligned select,
    /* NOTE:.help-inline is deprecated. Use .form-message-inline instead. */
.form-aligned .help-inline,
.form-message-inline {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    vertical-align: middle;
}
.form-aligned textarea {
    vertical-align: top;
}

/* Aligned Forms */
.form-aligned .control-group {
    margin-bottom: 0.75em;
}
.form-aligned .control-group label {
    text-align: right;
    display: inline-block;
    vertical-align: middle;
    width: 10em;
    margin: 0 1em 0 0;
}

.form-aligned .control-group .errors {
    margin: 0.4em 0 0 11em;
}

.form-aligned .control-group .errors li {
    margin: 0;
    color: #FF0000;
}

.form-aligned .controls {
    margin: 1em 0 0 11em;
}

/* Rounded Inputs */
.form input.input-rounded,
.form .input-rounded {
    border-radius: 2em;
    padding: 0.5em 1em;
}

/* Grouped Inputs */
.form .group fieldset {
    margin-bottom: 10px;
}
.form .group input {
    display: block;
    padding: 10px;
    margin: 0;
    border-radius: 0;
    position: relative;
    top: -1px;
}
.form .group input:focus {
    z-index: 2;
}
.form .group input:first-child {
    top: 1px;
    border-radius: 4px 4px 0 0;
}
.form .group input:last-child {
    top: -2px;
    border-radius: 0 0 4px 4px;
}
.form .group button {
    margin: 0.35em 0;
}

.form .input-1 {
    width: 100%;
}
.form .input-2-3 {
    width: 66%;
}
.form .input-1-2 {
    width: 50%;
}
.form .input-1-3 {
    width: 33%;
}
.form .input-1-4 {
    width: 25%;
}

/* Inline help for forms */
/* NOTE:.help-inline is deprecated. Use .form-message-inline instead. */
.form .help-inline,
.form-message-inline {
    display: inline-block;
    padding-left: 0.3em;
    color: #666;
    vertical-align: middle;
    font-size: 90%;
}

/* Block help for forms */
.form-message {
    display: block;
    color: #666;
    font-size: 90%;
}
