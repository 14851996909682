@-ms-viewport {
    width: device-width;
}

@media only screen and (min-width: 768px) and (max-width: 1001px) {
    .main,
    .header-wrapper,
    .content-wrapper {
        min-width: 765px;
    }

    .header,
    .content {
        width: 765px;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .main,
    .header-wrapper,
    .content-wrapper {
        min-width: 470px;
    }

    .header,
    .content {
        width: 470px;
    }

    .header {height: auto}
    .header .logo-wrapper {margin: 0}
}

@media only screen and (max-width: 479px) {
    .main,
    .header-wrapper,
    .content-wrapper {
        min-width: 320px;
    }

    .header,
    .content {
        width: 320px;
    }
    
    .header {height: auto}
    
    .header .logo-wrapper {
    	float: none;
    	width: auto;
    	text-align: center;
        margin: 0;
   	}
    
    .language-selection-wrapper {float: left}

    .message-content {padding: 20px 15px}
}