@media only screen and (max-width: 767px) {
    .row-grid {
        margin: 0;
    }

    .col-grid {
        position:relative;
        float: none;
        padding: 0;
    }

    div.col-grid {
        width: auto;
    }
}
