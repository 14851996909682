.flash-message {
	position: relative;
	width: auto;
	min-height: 20px;
	padding: 13px 10px 13px 70px;
	margin: 0 auto 20px;
	font-size: 16px;
	font-weight: 100;
}

.flash-message.compact {
    padding: 7px 5px 7px 35px;
    font-size: 14px;
    margin: 0 auto 10px;
}

.flash-message.fade-in {opacity: 0}

.flash-message .icon {
	position: absolute;
	display: block;
	top: 9px;
	left: 12px;
    width: 25px;
    height: 25px;
}

.flash-message.compact .icon {
    top: 4px;
    left: 1px;
}

.flash-message.warning {
	color: #947701;
	border: 1px solid #f8de67;
	background: #fcf2ce url('../img/flashes/flash-warning-separator.png') 50px top repeat-y;
}
.flash-message.compact.warning {background-position:26px top}
.flash-message.warning .icon {background: url('../img/flashes/flash-warning-icon.png') center center no-repeat}

.flash-message.success {
	color: #51811e;
	border: 1px solid #b6e08e;
	background: #eaf6e0 url('../img/flashes/flash-success-separator.png') 50px top repeat-y;
}
.flash-message.compact.success {background-position:26px top}
.flash-message.success .icon {background: url('../img/flashes/flash-success-icon.png') center center no-repeat}

.flash-message.info {
	color: #067799;
	border: 1px solid #81d7ee;
	background: #ddf4fa url('../img/flashes/flash-info-separator.png') 50px top repeat-y;
}
.flash-message.compact.info {background-position:26px top}
.flash-message.info .icon {background: url('../img/flashes/flash-info-icon.png') center center no-repeat}

.flash-message.error {
	color: #af1106;
	border: 1px solid #f49696;
	background: #fcf2f1 url('../img/flashes/flash-error-separator.png') 50px top repeat-y;
}
.flash-message.compact.error {background-position:26px top}
.flash-message.error .icon {background: url('../img/flashes/flash-error-icon.png') center center no-repeat}