body {
    font-family: 'PtSansRegular', Arial, 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
}

h1,h2,h3,h4,h5,h6 {
    font-family: 'PtSansRegular', Arial, 'Helvetica Neue', Helvetica, sans-serif;
	font-weight:400;
}

h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
	font-weight:inherit;
}

h1 {
	font-size:46px;
	line-height:50px;
	margin-bottom:14px;
}

h2 {
	font-size:35px;
	line-height:40px;
	margin-bottom:10px;
}

h3 {
	font-size:28px;
	line-height:34px;
	margin-bottom:8px;
}

h4 {
	font-size:21px;
	line-height:30px;
	margin-bottom:4px;
}

h5 {
	font-size:17px;
	line-height:24px;
}

h6 {
	font-size:14px;
	line-height:21px;
}

p {
	margin:0 0 20px;
}

p img {
	margin:0;
}

p.lead {
	font-size:21px;
	line-height:27px;
}

em {
	font-style:italic;
}

strong, b {
	font-weight:700;
}

small {
	font-size:80%;
}

blockquote,blockquote p {
	font-size:17px;
	line-height:24px;
	font-style:italic;
}

blockquote {
	border-left:1px solid #ddd;
	margin:0 0 20px;
	padding:9px 20px 0 19px;
}

blockquote cite {
	display:block;
	font-size:12px;
}

blockquote cite:before {
	content:"\2014 \0020";
}

blockquote cite a,blockquote cite a:visited,blockquote cite a:visited {
	color:#555;
}

hr {
	border:solid #ddd;
	clear:both;
	height:0;
	border-width:1px 0 0;
	margin:10px 0 30px;
}

a, .btn-link {
    color: #0792cc;
    text-decoration:underline;
    outline:0;
}

a:hover,a:focus {
	color:#000;
}

ul,ol {
	margin-bottom:20px;
}

ul {
	list-style:none outside;
}

ol,
ol li {
	list-style:decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
	margin-left:30px;
}

ul.square {
	list-style:square outside;
}

ul.circle {
	list-style:circle outside;
}

ul.disc {
	list-style:disc outside;
}

ul ul,ul ol,ol ol,ol ul {
	font-size:90%;
	margin:4px 0 5px 30px;
}

ul ul li,ul ol li,ol ol li,ol ul li {
	margin-bottom:6px;
}

li {
	line-height:18px;
	margin-bottom:12px;
}

ul.large li,li p {
	line-height:21px;
}
