/*
1000px grid system ~ Core CSS.
12 Columns ~ Margin left: 8px

Based on the simple grid system - http://www.webnicer.com/
by Jacek Ciolek

Created by the Grid System Generator - v1.00
Learn more ~ http://www.gridsystemgenerator.com/
*/

/* clearfix */
/*.clearfix:after, .row-grid:after {content:"."; display:block; height:0; clear:both; visibility:hidden;}*/
/*.clearfix, .row-grid {display:inline-block;}*/ /*Those two .clearfix have to be separate!)*/
/*.clearfix, .row-grid {display:block;}*/

/* gridframework */
.row-grid {position:relative; margin-left:-8px;}
.gu1 .row-grid {width: 84px;}
.gu2 .row-grid {width: 168px;}
.gu3 .row-grid {width: 252px;}
.gu4 .row-grid {width: 336px;}
.gu5 .row-grid {width: 420px;}
.gu6 .row-grid {width: 504px;}
.gu7 .row-grid {width: 588px;}
.gu8 .row-grid {width: 672px;}
.gu9 .row-grid {width: 756px;}
.gu10 .row-grid {width: 840px;}
.gu11 .row-grid {width: 924px;}
.gu12 .row-grid {width: 1008px;}

.col-grid {padding-left:8px; float:left; position:relative;}
.gu1{width: 76px;}
.gu2{width: 160px;}
.gu3{width: 244px;}
.gu4{width: 328px;}
.gu5{width: 412px;}
.gu6{width: 496px;}
.gu7{width: 580px;}
.gu8{width: 664px;}
.gu9{width: 748px;}
.gu10{width: 832px;}
.gu11{width: 916px;}
.gu12{width: 1000px;}
