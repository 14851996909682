/* helpers */
.fl {float: left}
.fr {float: right}
.clear {clear: both}

.relative {position: relative}

.orange {color: #ff5f02}
.blue {color: #0892cd}
.error, .red {color:red}
.gray {color: #777777}
.light-gray {color:#aaaaaa}

.italic {font-style:italic}
.strong {font-weight: 700}
.underline {text-decoration: underline}
.underline-dotted {
    text-decoration: none;
    border-bottom: 1px dotted;
}

.hidden {display: none}
.invisible {visibility:hidden}
.transparent {opacity: 0}

.inline-block {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.block-shadow {
    -webkit-box-shadow: 0 1px 6px rgba(50, 50, 50, 0.25);
    -moz-box-shadow: 0 1px 6px rgba(50, 50, 50, 0.25);
    box-shadow: 0 1px 6px rgba(50, 50, 50, 0.25);
}

.bottom-shadow {
    -webkit-box-shadow: 0 0 4px rgba(50, 50, 50, 0.25);
    -moz-box-shadow:    0 0 4px rgba(50, 50, 50, 0.25);
    box-shadow:         0 0 4px rgba(50, 50, 50, 0.25);
}

img.align-right {float:right; margin:0 0 1em 1em}
img.align-left {float:left; margin:0 1em 1em 0}
img.align-center {display: block; margin-left: auto; margin-right: auto}

.align-right {float:right}
.align-left {float:left}
.align-center {display: block; margin-left: auto; margin-right: auto}

.center-text {text-align:center}
.align-vertically {vertical-align: middle}

/* responsive utilities */
.responsive-image {
    display: block;
    width: 100%;
    height: auto;
}

.visible-xs {display:none}
.visible-ms {display:none}
.visible-md {display:none}
.visible-lg {}
.hidden-xs {}
.hidden-ms {}
.hidden-md {}
.hidden-lg {display: none}

/* margins */
.margin-10 {margin: 10px;}
.margin-top-10 {margin-top: 10px}
.margin-right-10 {margin-right: 10px}
.margin-bottom-10 {margin-bottom: 10px}
.margin-left-10 {margin-left: 10px}

.margin-top-20 {margin-top: 20px}
.margin-right-20 {margin-right: 20px}
.margin-bottom-20 {margin-bottom: 20px}
.margin-left-20 {margin-left: 20px}

.margin-top-40 {margin-top: 40px}
.margin-right-40 {margin-right: 40px}
.margin-bottom-40 {margin-bottom: 40px}
.margin-left-40 {margin-left: 40px}

.padding-0 {padding: 0}
.padding-top-0 {padding-top: 0}
.padding-right-0 {padding-right: 0}
.padding-bottom-0 {padding-bottom: 0}
.padding-left-0 {padding-left: 0}

.padding-10 {padding: 10px}
.padding-top-10 {padding-top: 10px}
.padding-right-10 {padding-right: 10px}
.padding-bottom-10 {padding-bottom: 10px}
.padding-left-10 {padding-left: 10px}

.padding-20 {padding: 20px}
.padding-top-20 {padding-top: 20px}
.padding-right-20 {padding-right: 20px}
.padding-bottom-20 {padding-bottom: 20px}
.padding-left-20 {padding-left: 20px}

.padding-top-bottom-10 {padding: 10px 0}
.padding-top-bottom-20 {padding: 20px 0}
.padding-top-bottom-30 {padding: 30px 0}

.text-indent-40 {text-indent: 40px}

.clearfix {
    *zoom: 1;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both;
}

.pull-left {
    float: left;
}
.pull-right {
    float: right;
}

@media only screen and (min-width: 768px) and (max-width: 1001px) {
    .visible-xs {display:none}
    .visible-ms {display:none}
    .visible-md {display: block}
    tr.visible-md {display: table-row}
    td.visible-md, th.visible-md {display: table-cell}
    .visible-lg {display:none}
    .hidden-xs {display:block}
    tr.hidden-xs {display: table-row}
    td.hidden-xs, th.hidden-xs {display: table-cell}
    .hidden-ms {display: block}
    tr.hidden-ms {display: table-row}
    td.hidden-ms, th.hidden-ms {display: table-cell}
    .hidden-md {display:none}
    .hidden-lg {display:block}
    tr.hidden-lg {display: table-row}
    td.hidden-lg, th.hidden-lg {display: table-cell}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .visible-xs {display:none}
    .visible-ms {display:block}
    tr.visible-ms {display: table-row}
    td.visible-ms, th.visible-ms {display: table-cell}
    .visible-md {display:none}
    .visible-lg {display:none}
    .hidden-xs {display:block}
    tr.hidden-xs {display: table-row}
    td.hidden-xs, th.hidden-xs {display: table-cell}
    .hidden-ms {display:none}
    .hidden-md {display:block}
    tr.hidden-md {display: table-row}
    td.hidden-md, th.hidden-md {display: table-cell}
    .hidden-lg {display:block}
    tr.hidden-lg {display: table-row}
    td.hidden-lg, th.hidden-lg {display: table-cell}
}

@media only screen and (max-width: 479px) {
    .visible-xs {display: block}
    tr.visible-xs {display: table-row}
    td.visible-xs, th.visible-xs {display: table-cell}
    .visible-ms {display:none}
    .visible-md {display:none}
    .visible-lg {display:none}
    .hidden-xs {display:none}
    .hidden-ms {display:block}
    tr.hidden-ms {display: table-row}
    td.hidden-ms, th.hidden-ms {display: table-cell}
    .hidden-md {display:block}
    tr.hidden-md {display: table-row}
    td.hidden-md, th.hidden-md {display: table-cell}
    .hidden-lg {display:block}
    tr.hidden-lg {display: table-row}
    td.hidden-lg, th.hidden-lg {display: table-cell}
}