html, body {
    height: 100%;
}

.main {
    position: relative;
    min-height: 100%;
    height: auto !important;
    height: 100%;
    width: 100%;
    min-width: 1000px;
    margin: 0 auto;
    background: #eee;
}

.header-wrapper {
    width: 100%;
    min-width: 1000px;
    height: auto;
    background: #fff;
    margin: 0 0 20px 0;
}

.header {
    width: 1000px;
    padding: 10px 0 5px 0;
    margin: 0 auto;
}

.logo-wrapper {
    float: left;
}

.logo-wrapper .logo {
    display: inline-block;
    width: 193px;
    height: auto;
}

.language-selection-wrapper {float: right}

.language-selection-wrapper .ddcommon {
    position: relative;
    float: right;
    display: block;
    width: 167px !important;
    margin: 6px 0 0;
    border: 1px solid #ccc;
    cursor: pointer;
}

.language-selection-wrapper .ddcommon:focus {outline:0}
.language-selection-wrapper .dd .ddArrow {background: url('../img/select-arrow.png') right center no-repeat}
.language-selection-wrapper .dd .ddTitle {background: #fff}
.language-selection-wrapper .language-select .ddTitle {color: #999999}
.language-selection-wrapper .ddcommon .shadow {box-shadow: none}
.language-selection-wrapper .dd .divider {display: none}
.language-selection-wrapper .dd .ddArrow {
    width: 21px;
    height: 16px;
}
.language-selection-wrapper .borderRadius,
.language-selection-wrapper .borderRadiusTp,
.language-selection-wrapper .borderRadiusTp .border,
.language-selection-wrapper .borderRadius .border,
.language-selection-wrapper .borderRadiusTp ul li:last-child {
    border-radius: 0
}
.language-selection-wrapper .ddChild {height: auto !important}
.language-selection-wrapper .dd .ddChild li {
    margin: 0;
    padding: 5px;
    background: #eee;
    color: #000000;
}

.language-selection-wrapper .dd .ddChild li.selected {background: #d5d5d5}
.language-selection-wrapper .dd .ddChild li:hover {background: #f2f2f2}
.language-selection-wrapper .dd .ddChild li.selected:hover {background: #d5d5d5}

.language-selection-wrapper .language-label {
    display: block;
    float: right;
    height: 39px;
    line-height: 39px;
    margin: 0 20px 0 0;
    color: #999999;
}

.content-wrapper {width: 100%}

.content {
    width: 1000px;
    margin: 0 auto;
}

.message-block-wrapper {
    width: auto;
    margin: 0 auto 20px;
    background: #fff;
}

.message-header {
    position: relative;
    width: auto;
    min-height: 20px;
    padding: 13px 50px 13px 70px;
    color: #fff;
    font-size: 18px;
    background: #276e9c url('../img/vertical-separator-default.png') 50px top repeat-y;
    border: 1px solid #276e9c;
}

.message-block-wrapper.error .message-header {
    background: #d83339 url('../img/vertical-separator-error.png') 50px top repeat-y;
    border: 1px solid #d83339;
}

.message-block-wrapper.success .message-header {
    color: #fff;
    font-size: 16px;
    background: #a6d879 url('../img/vertical-separator-green.png') 50px top repeat-y;
    border: 1px solid #a6d879;
}

.message-header .icon {
    position: absolute;
    left: 15px;
    top: 10px;
}

.message-header .number {
    position: absolute;
    left: 21px;
    top: 15px;
    font-size: 18px;
    font-weight: 700;
}

.message-header .arrow {
    position: absolute;
    top: 50%;
    right: 0;
    width: 25px;
    height: 25px;
    margin: -13px 12px 0 0;
    cursor: pointer;
}

.message-header .arrow.arrow-down {background: url('../img/light-blue-arrow-down.png') center center no-repeat}
.message-header .arrow.arrow-right {background: url('../img/light-blue-arrow-right.png') center center no-repeat}

.message-content {
    width: auto;
    height: auto;
    margin: 0;
    padding: 20px 70px;
    border: 1px solid #d2d2d2;
}

.safe-management-content {
    padding: 20px 15px;
}

.message-footer {
    width: auto;
    height: auto;
    margin: 0;
    padding: 5px;
    border: 1px solid #d2d2d2;
    border-top:0;
}

.message-content.no-padding {
    padding: 0;
}

.main-menu > li {
    position: relative;
    padding: 0;
    margin: 0 0 5px 0;
}

.main-menu > li > a,
.main-menu > li > span {
    display: block;
    width: auto;
    padding: 12px 12px 12px 57px;
    text-decoration: none;
}

.main-menu > li.current > span {
    color: #286f9d;
    background: #c7d5de url('../img/menu-icons/menu-arrrow-current.png') right center no-repeat;
}

.main-menu > li:hover a {
    background: #dddddd url('../img/menu-icons/menu-arrow.png') right center no-repeat;
}
.main-menu > li.current:hover span {
    background: #c7d5de url('../img/menu-icons/menu-arrrow-current.png') right center no-repeat;
}

.main-menu > li.logout {
    background-image: url('../img/menu-icons/logout-line.png');
    background-position: bottom left;
    background-repeat: repeat-x;
    padding-bottom: 2px;
}

/* icons */
.main-menu > li > a > i,
.main-menu > li > span > i {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 35px;
    height: 35px;
    margin-top: -18px;
}

.main-menu > li.cash-in > a > i,
.main-menu > li.cash-in > span > i {
    background: url('../img/menu-icons/cashin-icon.png') center center no-repeat;
}

.main-menu > li.cash-out > a > i,
.main-menu > li.cash-out > span > i {
    background: url('../img/menu-icons/cashout-icon.png') center center no-repeat;
}

.main-menu > li.identity > a > i,
.main-menu > li.identity > span > i {
    background: url('../img/menu-icons/identification-icon.png') center center no-repeat;
}

.main-menu > li.settings > a > i,
.main-menu > li.settings > span > i {
    background: url('../img/menu-icons/settings-icon.png') center center no-repeat;
}

.main-menu > li.logout > a > i,
.main-menu > li.logout > span > i {
    background: url('../img/menu-icons/logout-icon.png') center center no-repeat;
}

ul.form_errors {
    margin: 0.5em 0.35em 0.2em;
    color: #ff0000;
}
ul.form_errors li {
    margin:0;
}

/**/
form.compound > .control-group {
    border-bottom:1px solid #e5e5e5;
    margin-bottom: 1.5em;
    padding-bottom: 0.7em;
}
form.compound > .control-group > label {
    font-weight: bold;
}
form.compound .calculation_ajax_wrapper {
    margin-top: 1.5em;
    margin-bottom: 0.75em;
}
form.compound .response,
form.compound .loading_text {
    margin: 0.5em 0.35em 0.2em;
}
form.compound .response .title {
    font-weight:bold;
    margin-bottom:0.75em;
}
form.compound .loading_text {
    color:#C0C0C0;
}

.initial-balance .heading, .current-balance .heading {
    font-weight:bold;
    margin-bottom:0.5em;
}
.initial-balance .negative, .current-balance .negative  {
    color:red;
}
.initial-balance > .amount, .current-balance > .amount {
    border-bottom: 1px solid #C0C0C0;
    clear: both;
    height: 1.5em;
    margin-bottom: 0.5em;
}
.initial-balance > .amount > .amount, .current-balance >  .amount > .amount {
    float:left;
}
.initial-balance > .amount > .currency, .current-balance >  .amount > .currency {
    float:right;
    font-weight:bold;
}

.compound_confirm > .wallet > .wallet {
    color:#FF0000;
}
.compound_confirm > .operation > div {
    padding-left:15px;
}
.compound_confirm > .operation .title {
    padding-left:0;
}
.compound_confirm > .operation .amount {
    font-weight:bold;
    color:#FF0000;
}
.compound_confirm > .operation .description {
    font-style: italic;
}
.compound_confirm form > div {
    display: none;
}
.compound_confirm .preview_submit {
    margin-right:0.5em;
}
.compound_confirm a.print-button {
    display:inline-block;
    margin-top:0.5em;
}
.account-card {
    vertical-align: text-top;
}

/* Transactions */
.transactions .payments { margin-left:1.5em; }
.transactions .title { font-weight: bold; }
.transactions .transaction_status, .transactions .payment_status { font-weight:bold; }
.transactions .buttons { margin-top:0.5em; }
.transactions .buttons .button-container { display:inline-block; }

/* Cash In/Out */
.cash-in .commission.currency,
.cash-out .commission.currency {
    pointer-events: none;
}

/* Cash In/Out Selfie */

.client-selfie-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
}

.client-selfie-wrapper .client-balance {
    max-width: 160px;
    width: 100%;
}

.client-selfie-wrapper .client-selfie {
    position: relative;
    display: flex;
    padding: 5px;
    width: 160px;
    height: 160px;
    align-items: center;
    justify-content: center;
    border: 1px solid #CCCCCC;
}

.client-selfie-wrapper .client-selfie img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    z-index: 1;
}

.client-selfie-wrapper .client-selfie:before {
    content: attr(data-no-selfie);
    position: absolute;
    text-align: center;
    padding: 10px;
    z-index: 0;
}

/* Tooltip container */

.with-tooltip {
    position: relative;
}

.cash-in .tooltip,
.cash-out .tooltip {
    position: absolute;
    display: inline-block;
    left: -16px;
    top: -13px;
}

/* Tooltip text */
.cash-in .tooltip .tooltiptext,
.cash-out .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: #fff;
    color: #000;
    border: 1px solid black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    margin-left: 0;
    opacity: 0;
    transition: opacity 0.3s;
}

.cash-in .tooltip .tooltiptext::before,
.cash-out .tooltip .tooltiptext::before {
    content: "";
    position: absolute;
    bottom: -22px;
    left: 10px;
    border: 11px solid transparent;
    border-top-color: #000;
    display: block;
}

.cash-in .tooltip .tooltiptext::after,
.cash-out .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 11px;
    border: 10px solid transparent;
    border-top-color: #fff;
    display: block;
}

/* Show the tooltip text when `show` class is added */
.cash-in .tooltip.show .tooltiptext,
.cash-out .tooltip.show .tooltiptext {
    visibility: visible;
    opacity: 1;
}

/* Identity confirmation page */
input.form_item {
    margin: 5px 0 10px;
}
label.form_label {
    display: block;
}
label.form_text_label {
    margin-right: 15px;
}

/* Pagination */
.pagination {
    flex-wrap: wrap;
    justify-content: center;
}
.pagination > span {
    margin: 0 2px;
}

/* Transaction confirmation modal */
#submit-btn-loader,
#menu-btn-loader {
    visibility: hidden;
    position: absolute;
    right: calc(50% - 0.5rem);
    opacity: 0.7;
}
