/* Common styles */

.modal {
    --bs-modal-width: 600px;
}

@media screen and (min-width: 768px) {
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
}

/* Transaction Confirmation popup */
.modal-footer .right-column {
    margin-left: auto;
}
