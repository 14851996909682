.table {
    /* Remove spacing between table cells (from Normalize.css) */
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #cbcbcb;
    margin: 0 0 20px;
}

.table caption {
    color: #000;
    font: italic 85%/1 arial, sans-serif;
    padding: 1em 0;
    text-align: center;
    caption-side:top;
}

.table td,
.table th {
    border-left: 1px solid #cbcbcb;/*  inner column border */
    border-width: 0 0 0 1px;
    font-size: inherit;
    margin: 0;
    overflow: visible; /*to make ths where the title is really long work*/
    padding: 8px; /* cell padding */
}
.table td:first-child,
.table th:first-child {
    border-left-width: 0;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-top: 1px solid #dddddd;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    color: #333;
}
.table thead,
.table thead th,
.table thead > * > * {
    background: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
}

/*
striping:
   even - #fff (white)
   odd  - #f2f2f2 (light gray)
*/
.table td {
    background-color: transparent;
}
.table-odd td {
    background-color: #f2f2f2;
}

/* nth-child selector for modern browsers */
.table-striped tr:nth-child(2n-1) td {
    background-color: #f2f2f2;
}

/* BORDERED TABLES */
.table-bordered td {
    border-bottom: 1px solid #cbcbcb;
}
.table-bordered tbody > tr:last-child td,
.table-horizontal tbody > tr:last-child td {
    border-bottom-width: 0;
}


/* HORIZONTAL BORDERED TABLES */

.table-horizontal td,
.table-horizontal th {
    border-width: 0 0 1px 0;
    border-bottom: 1px solid #cbcbcb;
}
.table-horizontal tbody > tr:last-child td {
    border-bottom-width: 0;
}


/* TRANSFERS TABLE ON SAFE MANAGEMENT PAGE */
.table.transfers > tbody > tr > td,
.table.transfers > tbody > tr > th,
.table.transfers > tfoot > tr > td,
.table.transfers > tfoot > tr > th,
.table.transfers > thead > tr > td,
.table.transfers > thead > tr > th {
    padding: 5px;
}


/* RESERVATIONS TABLE */
.table.reservations > tbody > tr > td,
.table.reservations > tbody > tr > th,
.table.reservations > tfoot > tr > td,
.table.reservations > tfoot > tr > th,
.table.reservations > thead > tr > td,
.table.reservations > thead > tr > th {
    padding: 5px;
}
