.borderRadius{-moz-border-radius:5px; border-radius:5px; }
.borderRadiusTp{-moz-border-radius:5px 5px 0 0; border-radius:5px 5px 0 0;}
.borderRadiusBtm{-moz-border-radius:0 0 5px 5px ; border-radius:0 0 5px 5px;}

.ddcommon {position:relative;display:-moz-inline-stack; zoom:1; display:inline-block; *display:inline; cursor:default;}
.ddcommon ul{padding:0;margin:0;}
.ddcommon ul li{list-style-type:none;}
.borderRadiusTp ul li:last-child{-moz-border-radius:0 0 5px 5px ; border-radius:0 0 5px 5px;border-bottom:0 none #c3c3c3; }
.borderRadiusBtm ul li:first-child{-moz-border-radius:5px 5px 0 0; border-radius:5px 5px 0 0 ;border-bottom:1 solid #c3c3c3; }

.ddcommon .disabled img, .ddcommon .disabled span, .ddcommon.disabledAll{
opacity: .5; /* standard: ff gt 1.5, opera, safari */
-ms-filter:"alpha(opacity=50)"; /* ie 8 */
filter:alpha(opacity=50); /* ie lt 7 */
-khtml-opacity:.5; /* safari 1.x */
-moz-opacity:.5; /* ff lt 1.5, netscape */
color:#999999;
}
.ddcommon .clear{clear:both}
.ddcommon .shadow{-moz-box-shadow:5px 5px 5px -5px #888888;-webkit-box-shadow:5px 5px 5px -5px #888888;box-shadow: 5px 5px 5px -5px #888888;}
.ddcommon input.text{color:#7e7e7e;padding:0 0 0 0; position:absolute; background:#fff; display:block; width:98%; height:98%; left:2px; top:0; border:none;}
.ddOutOfVision{position:relative; display:-moz-inline-stack; display:inline-block; zoom:1; *display:inline;}
.borderRadius .shadow{-moz-box-shadow:5px 5px 5px -5px #888888;-webkit-box-shadow:5px 5px 5px -5px #888888;box-shadow: 5px 5px 5px -5px #888888;}
.borderRadiusBtm .shadow{-moz-box-shadow:-5px -5px 5px -5px #888888;-webkit-box-shadow:-5px -5px 5px -5px #888888;box-shadow: -5px -5px 5px -5px #888888}
.borderRadiusTp .border, .borderRadius .border{-moz-border-radius:0 0 5px 5px ; border-radius:0 0 5px 5px;}
.borderRadiusBtm .border{-moz-border-radius:5px 5px 0 0; border-radius:5px 5px 0 0;}
img.fnone{float:none !important}
.ddcommon .divider{width:0; height:100%; position:absolute;}
.ddcommon .ddArrow{display:inline-block; position:absolute; top:50%; right:4px;}
.ddcommon .ddArrow:hover{background-position:0 100%;}
.ddcommon .ddTitle{padding:0; position:relative; display:inline-block; width:100%}
.ddcommon .ddTitle .ddTitleText{display:block;}
.ddcommon .ddTitle .ddTitleText .ddTitleText{padding:0;}
.ddcommon .ddTitle .description{display:block;}
.ddcommon .ddTitle .ddTitleText img{position:relative; vertical-align:middle; float:left}
.ddcommon .ddChild{position:absolute;display:none;width:100%;overflow-y:auto; overflow-x:hidden; zoom:1; z-index:9999}
.ddcommon .ddChild li{clear:both;}
.ddcommon .ddChild li .description{display:block;}
.ddcommon .ddChild li img{border:0 none; position:relative;vertical-align:middle;float:left}
.ddcommon .ddChild li.optgroup{padding:0;}
.ddcommon .ddChild li.optgroup .optgroupTitle{padding:0 5px; font-weight:bold; font-style:italic}
.ddcommon .ddChild li.optgroup ul li{padding:5px 5px 5px 15px}
.ddcommon .noBorderTop{border-top:none 0  !important; padding:0; margin:0;}

/*************** default theme **********************/
.dd{border:1px solid #c3c3c3;}
.dd .divider{border-left:1px solid #c3c3c3; border-right:1px solid #fff;; right:24px;}
.dd .ddArrow{width:16px;height:16px; margin-top:-8px; background:url(../../images/msdropdown/skin1/dd_arrow.gif) no-repeat;}
.dd .ddArrow:hover{background-position:0 100%;}
.dd .ddTitle{color:#000;background:#e2e2e4 url(../../images/msdropdown/skin1/title-bg.gif) repeat-x left top;}
.dd .ddTitle .ddTitleText{padding:5px 20px 5px 5px;}
.dd .ddTitle .ddTitleText .ddTitleText{padding:0;}
.dd .ddTitle .description{font-size:12px; color:#666}
.dd .ddTitle .ddTitleText img{padding-right:5px;}
.dd .ddChild{border:1px solid #c3c3c3; background-color:#fff; left:-1px;}
.dd .ddChild li{padding:5px; background-color:#fff; border-bottom:1px solid #c3c3c3;}
.dd .ddChild li .description{color:#666;}
.dd .ddChild li .ddlabel{color:#333;}
.dd .ddChild li.hover{background-color:#f2f2f2}
.dd .ddChild li img{padding:0 6px 0 0;}
.dd .ddChild li.optgroup{padding:0;}
.dd .ddChild li.optgroup .optgroupTitle{padding:0 5px; font-weight:bold; font-style:italic}
.dd .ddChild li.optgroup ul li{padding:5px 5px 5px 15px}
.dd .ddChild li.selected{background-color:#d5d5d5; color:#000;}
